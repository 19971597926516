<template>
  <div class="home">
    <div class="header">
      <div class="container grid md:grid-cols-2 items-center pb-12 md:pb-0">
        <div class="slider h-full overflow-none">
          <swiper
            :modules="[EffectFade, Pagination, Autoplay]"
            :slides-per-view="1"
            class="h-full"
            :pagination="{ clickable: true }"
            :effect="'fade'"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
          >
            <swiper-slide v-for="i in 6" :key="i">
              <img :src="`/img/img${i}.png`" alt="" class="w-full h-[400px] md:h-[800px] object-cover ">
            </swiper-slide>
          </swiper>
        </div>
        <div
          class="info relative text-right pt-5 md:h-[700px] md:flex flex-col justify-center items-end pr-10 md:pt-44">
          <router-link to="#" class="text-[30px] md:text-[48px] font-bold text-[#F09125] leading-none">“ULTTYQ SPORT
            QORY” – ұлттық спорт түрлерін қолдау қоры.
          </router-link>
          <!--              <span class="text-[26px] block md:text-[40px] font-bold text-white leading-none my-5">ҰЛТТЫҚ СПОРТТЫ<br> ҚОЛДАУ ҚОРЫ</span>-->
          <div
            @click="modal = true"
            class="mt-5 cursor-pointer btn h-10 md:h-14 w-full px-10 flex items-center bg-[#E9961C] md:w-max text-lg text-white font-bold">
            Өтініш беру
          </div>
        </div>
      </div>
    </div>

    <div class="desc_1 relative flex items-center ">
      <img src="@/assets/img/oyu_1.png" alt="" class="absolute bottom-0 right-0 w-[600px]">
      <div class="container grid md:grid-cols-[1fr_500px] gap-10">
        <div class="w-3/4">
          <p class="text-[26px] md:text-[48px] font-bold italic leading-none mb-4">«ҰЛТТЫҚ СПОРТ ҚОРЫ» ұлттық спортты
            қолдау қоры</p>
          <span class="md:text-lg">Қор дене шынықтыру мен спортты дамытуға бағытталған бюджеттен тыс ақша қаражатын бөлу жөніндегі бірыңғай оператор болып табылады. Ұйым қайырымдылық көмек көрсетуге өтініштерді қабылдаумен, өңдеумен және талдаумен айналысады. Жобалар бойынша шешімдерді Қордың Қамқоршылық кеңесі қабылдайды.</span>
        </div>
        <swiper
          :slides-per-view="1"
          class="h-full"
          :modules="[Autoplay]"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide>
            <img src="@/assets/img/img1.png" alt="" class="w-full object-cover ">
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="news gradient py-10 md:py-20">
      <div class="container">
        <img src="@/assets/img/attar_white.png" alt="" class="w-full hidden md:block">

        <h3 class="md:text-[48px] italic font-bold text-[#F09125] md:mt-10 mb-4">ЖАҢАЛЫҚТАР</h3>
        <div class="md:grid grid-cols-2 gap-10">
          <NewsCard :data="newsList[0]"/>
          <div class="md:grid grid-cols-2 gap-10">
            <NewsCard :mini="true" v-for="n in newsList.slice(1, 5)" :key="n.id" :data="n" class="mt-4 md:mt-0"/>
          </div>
        </div>
      </div>
    </div>

    <div class="news py-10 md:py-20 relative">
      <img src="@/assets/img/oyu_1.png" alt="" class="absolute bottom-0 right-0 w-[600px]">
      <div class="container">
        <img src="@/assets/img/attar.png" alt="" class="w-full hidden md:block">

        <h3 class="md:text-[30px] italic font-bold md:mt-10 mb-4">ҚАЙЫРЫМДЫЛЫҚ КӨМЕК КӨРСЕТУ ПРОЦЕСІ</h3>

        <ul class="md:grid grid-cols-3 gap-10 pt-10">
          <li class="pl-10 relative" v-for="(k, idx) in list" :key="idx">
            <p class="absolute text-gray-200 -top-2 leading-none -left-6 text-[86px] font-bold">{{ idx + 1 }}</p>
            <p class="font-bold mb-1 text-xl italic">{{ k[0] }}</p>
            <span class="text-sm">{{ k[1] }}<br> <br>{{ k[2] }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="news py-10  md:py-20 relative gradient">
      <div class="container text-white">
        <div class="flex justify-between items-center md:mt-10 mb-10">
          <h3 class="md:text-[30px] italic font-bold">ЖОБАЛАРДЫ ЖҮЗЕГЕ АСЫРУҒА КӨМЕКТЕСТІК</h3>
          <div class="flex justify-between items-center gap-2">
            <div class="prev cursor-pointer"><img src="@/assets/img/icons/left.png" alt=""></div>
            <div class="next cursor-pointer"><img src="@/assets/img/icons/right.png" alt=""></div>
          </div>
        </div>

        <div>
          <swiper
            :freeMode="true"
            :observer="true"
            :observeParents="true"
            :pagination="{
              clickable: true,
            }"
            :navigation="{
              nextEl: '.next',
              prevEl: '.prev',
            }"
            :breakpoints="{
              '640': {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '768': {
                slidesPerView: 2.5,
                spaceBetween: 40,
              },
            }"
            :modules="[FreeMode, Navigation]"
          >
            <swiper-slide v-for="l in projectsList" :key="l">
              <div class="relative card italic text-white h-[200px]">
                <a :href="l.link" target="_blank">
                  <img :src="baseUrl + l.logo?.url" alt="" class="w-full h-full object-contain rounded-full">
                </a>
                <!--                <div class="absolute w-full pb-5 bottom-0 left-0 gradient-2 p-4">-->
                <!--                  <p class="font-bold italic text-[14px] line-clamp-2">{{ l.logo }}</p>-->
                <!--                  <span class="text-sm">{{ l.date }}</span>-->
                <!--                </div>-->
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

    <div class="news overflow-hidden py-10 pb-16">
      <div class="container relative">
        <div class="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 absolute top-0 left-0 w-full opacity-25 h-full">
          <img src="../assets/img/img_1.jpg" alt="" class="w-full h-full object-cover -skew-x-6">
          <img src="../assets/img/img_2.jpg" alt="" class="w-full h-full object-cover -skew-x-6">
        </div>
        <div class="relative md:px-20 p-10 md:py-28">
          <div class="relative md:grid grid-cols-2">
            <div>
              <p class="text-[28px] mb-5 md:mb-0 md:text-[40px] italic font-bold">ҚОЛДАУ АЛУ</p>
              <span>Клубыңызға немесе ұйымыңызға қолдау <br> алу үшін өтініш жіберіңіз.</span>
            </div>
          </div>
          <div class="relative my-10 md:my-0 md:grid grid-cols-[1fr_auto_1fr] justify-center">
            <div></div>
            <div
              @click="modal = true"
              class=" cursor-pointer btn h-14 px-10 flex items-center bg-[#E9961C] w-max text-lg text-white font-bold">
              Өтініш беру
            </div>
            <div></div>
          </div>
          <div class="relative md:grid grid-cols-3">
            <div></div>
            <div></div>
            <div>
              <p class="text-[28px] mb-5 md:mb-0 md:text-[40px] italic font-bold">ДЕМЕУШІЛЕРГЕ</p>
              <span>Қазақстандағы спорт пен дене шынықтыруды дамытуға көмектесуге дайын болсаңыз.</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10 md:py-20 relative gradient">
      <img src="@/assets/img/oyu_2.png" class="absolute bottom-0 left-0 md:h-[100px]" alt="">
      <div class="container relative text-white grid md:grid-cols-[1fr_300px] gap-6">
        <div>
          <h3 class="md:text-[30px] italic font-bold">Қордың негізін қалаушы, құрылтайшы – Еркебұлан Сайдуллаұлы
            Ильясов</h3>
          <p class="py-5">“Alageum Electric” КТ Директорлар кеңесінің Төрағасы, ҚР Жамбы ату және арқан тартыс
            федерациясының президенті</p>
          <router-link to="#" class="btn h-10 px-10 flex items-center bg-[#E9961C] w-max text-lg text-white font-bold">
            Сұрақ қою
          </router-link>
        </div>
        <img src="@/assets/img/director.png" class="" alt="">
      </div>
    </div>

    <div class="py-20 relative overflow-hidden">
      <img src="@/assets/img/oyu_1.png" alt="" class="absolute -bottom-20 -left-10 w-[600px] rotate-90">
      <div class="container relative grid md:grid-cols-[1fr_1fr] gap-6">
        <div>
          <h3 class="text-[26px] md:text-[40px] italic font-bold text-[#09426d]">БАЙЛАНЫС</h3>

          <ul class="font-bold">
            <li class="mt-5">
              <p class="text-sm">Кеңес алу үшін</p>
              <a href="tel:+7 702 460 33 00" target="_blank" class="md:text-3xl italic">+7 702 460 33 00</a>
            </li>
            <li class="mt-5">
              <p class="text-sm">Мекен-жай</p>
              <span class="text-xl italic">Астана қ., Сауран 15/4</span>
            </li>
            <li class="mt-5">
              <p class="text-sm">Жұмыс кестесі</p>
              <span
                class="text-xl italic">Дүйсенбі - жұма: 9:00 -18:00 <br> Сенбі - Жексенбі: демалыс</span>
            </li>
            <li class="mt-5">
              <p class="text-sm">E-mail</p>
              <span class="text-xl italic">usqory@gmail.com</span>
            </li>
            <li class="mt-5">
              <p class="text-sm">Instagram</p>
              <a href="https://www.instagram.com/ulttyq.sportqory?igsh=YWVuYWZrNTI5eWRn" target="_blank"
                 class="text-xl italic">@ulttyq.sportqory </a>
            </li>
          </ul>
        </div>
        <img src="@/assets/img/map.png" class="w-full" alt="">
      </div>
    </div>

    <ApplicationModal :class="{active: modal}" @close="modal=false"/>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectFade, FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import NewsCard from '@/components/NewsCard.vue'
import { ref } from 'vue'
import axios from '@/api/axios'
import { baseUrl } from '@/env'
import ApplicationModal from '@/components/ApplicationModal.vue'

const list = [
  ['Өтініш', 'коммерциялық емес, заңды, жеке тұлғалар', 'Тіркелген сәттен бастап 20 жұмыс күніне дейін қаралады'],
  ['Жобаны қарау', 'жобаны Қамқоршылық кеңестің отырысына шығару', 'Өтінім қаралғаннан кейін 10 жұмыс күні ішінде шешім қабылданады және шығындар есебі бекітіледі.'],
  ['Шешім', 'келісімшарт жасасу', 'Жоба бойынша оң шешім қабылданған жағдайда 7 жұмыс күні ішінде келісімшарт жасалады.'],
  ['Түзету', 'қажет болған жағдайда', 'Түзету алушының негіздемесі, растайтын құжаттар және есептері болған жағдайда қаралады және 10 жұмыс күні ішінде шығындар есебін нақтылаумен шешім қабылданады'],
  ['Есеп беру', 'тоқсан сайынғы, жартыжылдық, жылдық негізде', 'Қайырымдылық көмек алғаннан кейін көмек алушылар есеп береді.'],
]

const newsTop = ref([])
const newsList = ref([])
const projectsList = ref([])
const modal = ref(false)

axios.get('/news?&populate=*').then(res => {
  newsTop.value = res.data.data
})

axios.get('/news?populate=*&sort[0]=date:desc').then(res => {
  newsList.value = res.data.data
})

axios.get('/projects?populate=*').then(res => {
  projectsList.value = res.data.data
})
</script>

<style lang="scss" scoped>
.header {
  background: linear-gradient(45deg, rgba(224, 225, 226, 1) 0%, rgba(27, 31, 42, 1) 0%, rgba(9, 66, 109, 1) 100%);

  .info {
    clip-path: polygon(100% 100%, 0 100%, 25% 0, 125% 0, 100% 100%);
  }

  .slider {
    img {
      min-width: 125%;
      left: 0;
      position: relative;
      clip-path: polygon(100% 100%, 0 100%, 28% 0, 100% 0, 72% 100%);
    }
  }
}

.desc_1 {
  height: 600px;
}


.card {
  clip-path: polygon(96.525% 100%, 0 100%, 3.288% 0, 99.82% 0, 96.525% 100%);
}


@media only screen and (max-width: 600px) {
  .desc_1{
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
  }
  .header {
    padding: 20px 0;

    .slider {
      img {
        width: 100%;
        clip-path: none;
      }
    }

    .info {
      clip-path: none;
    }
  }
}
</style>
